export const DEBUG_SESSION_KEY = 'logincheck.debug';

export const LC_COMPONENT_NAME = "Declaration of Consent 1.0.7"

export const isIE11 = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;

/**
 * Function for UUID generation taken from https://stackoverflow.com/a/21963136 and formatted according to our code standard.
 */
/* eslint-disable */
export function generateUUID() {
    let lut = [],
        format = ['x', 'x', 'x', 'x', '-', 'x', 'x', '-', '4', 'x', '-', 'y', 'x', '-', 'x', 'x', 'x', 'x', 'x', 'x',],
        uuid = '',
        i = 0,
        randomNumber = (Math.random() * 0xffffffff) | 0;

    // Generate lookup table (lut)
    for (let i = 0; i < 256; i++) {
        lut[i] = (i < 16 ? '0' : '') + i.toString(16);
    }

    // Generate UUID based on given format
    while (i++ < 20) {
        let c = format[i - 1],
            r = randomNumber & 0xff,
            v = c == 'x' ? r : c == 'y' ? (r & 0x3f) | 0x80 : (r & 0xf) | 0x40;

        uuid += c == '-' ? c : lut[v];
        randomNumber = i % 4 == 0 ? (Math.random() * 0xffffffff) | 0 : randomNumber >> 8;
    }

    return uuid;
}

export function getTrackingId() {
    /**
     * @return {string}
     */
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1); // eslint-disable-line no-bitwise
    };
    return (S4() + S4() + S4() + S4());
}

export function logIfDebugEnabled(message, ...additionalLogData) {
    // IE11 does not provide a console object if the developer console was not opened before
    // and generates an exception if existence is not checked
    if (console && typeof console.log === 'function' && window.sessionStorage.getItem(DEBUG_SESSION_KEY)) { // eslint-disable-line no-console
        console.log(`LOGIN CHECK [${LC_COMPONENT_NAME}] :: ${message}`, ...additionalLogData); // eslint-disable-line no-console
    }
}

/**
 * Extract the canonical path from the <link canonical> tag.
 *
 * @returns {boolean|*}
 * @private
 */
export function getCanonicalPathHref() {
    // According to caniuse querySelector support is not given in certain mobile browser.
    // So we check the availability here.
    if (document.querySelector) {
        const element = document.querySelector('link[rel=\'canonical\']');
        // querySelector returns null if element is not found - so we have to guard against that
        if (element) {
            return element.href;
        }
    }

    return false;
}

/**
 * htmlToElement - returns a HTMLElement from the provided html string
 * @param {String} html element to be generated
 * @return {HTMLElement} element generated
 */
export function htmlToElement(html) {
    const template = document.createElement(isIE11 ? 'div' : 'template');
    template.innerHTML = html.trim();
    return isIE11 ? template.firstChild : template.content.firstChild;
}